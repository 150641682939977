<!-- eslint-disable vue/no-v-html -->
<template>
  <v-skeleton-loader
    v-if="isSkeleton"
    type="article, actions"
  />
  <v-card v-else>
    <v-card-title class="justify-center">
      Histórico de RIC'S
    </v-card-title>

    <v-card-text>
      <v-timeline
        dense
        class="timeline-custom-dense timeline-custom-dots mt-4"
      >
        <v-timeline-item
          v-for="{id, protocol, employer, status, complaint} in data"
          :key="id"
          small
          class="mb-6"
          :color="getStatusColor(status)"
        >
          <div class="container-content">
            <div>
              <h3>{{ protocol }} - {{ employer.name }}</h3>
            </div>
          </div>

          <div class="container-status">
            <h4 class="d-flex align-center">
              Status:
            </h4>
            <v-alert
              class="badge"
              border="left"
              outlined
              dense
              :type="getStatusColor(status)"
              :color="getStatusColor(status)"
            >
              {{ status }}
            </v-alert>
          </div>
          <div class="container-description">
            <h4>
              Descrição:
            </h4>
            <div
              v-html="sanitizedDescription(complaint)"
            ></div>
          </div>
          <v-divider></v-divider>
        </v-timeline-item>
      </v-timeline>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal()"
        >
          Fechar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import DOMPurify from 'dompurify'

export default {
  mixins: [formatters, messages],

  props: {
    employeeId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      data: [],
      isSkeleton: false,
    }
  },

  created() {
    this.getDataById()
  },

  methods: {
    async getDataById() {
      this.isSkeleton = true

      await axiosIns
        .get(`api/v1/sgq/sac/sac_employer/${this.employeeId}`)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
          this.closeModal()
        })
        .finally(() => { this.isSkeleton = false })
    },

    /**
     * Returns the corresponding color for a given status.
     *
     * @param {string} status - The status to get the color for.
     * @return {string} The color corresponding to the given status.
     */
    getStatusColor(status) {
      const statusColorMap = {
        'AGUARDANDO RD': 'info',
        'AGUARDANDO CENTRAL': 'orange',
        'AGUARDANDO ENCERRAMENTO': 'purple',
        'AGUARDANDO LÍDER': 'white',
        FINALIZADO: 'success',
      }

      return statusColorMap[status] || ''
    },

    sanitizedDescription(description) {
      return DOMPurify.sanitize(description)
    },
  },
}
</script>

<style scoped>
.container-status {
  display: flex;
  align-content: center;
  margin-top: 10px;
}

.container-description {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: small;
  height: 30px;
  width: 250px;
  margin-left: 10px;
  margin-bottom: 0;

}
</style>
