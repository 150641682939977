import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSkeleton)?_c(VSkeletonLoader,{attrs:{"type":"article, actions"}}):_c(VCard,[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(" Histórico de RIC'S ")]),_c(VCardText,[_c(VTimeline,{staticClass:"timeline-custom-dense timeline-custom-dots mt-4",attrs:{"dense":""}},_vm._l((_vm.data),function(ref){
var id = ref.id;
var protocol = ref.protocol;
var employer = ref.employer;
var status = ref.status;
var complaint = ref.complaint;
return _c(VTimelineItem,{key:id,staticClass:"mb-6",attrs:{"small":"","color":_vm.getStatusColor(status)}},[_c('div',{staticClass:"container-content"},[_c('div',[_c('h3',[_vm._v(_vm._s(protocol)+" - "+_vm._s(employer.name))])])]),_c('div',{staticClass:"container-status"},[_c('h4',{staticClass:"d-flex align-center"},[_vm._v(" Status: ")]),_c(VAlert,{staticClass:"badge",attrs:{"border":"left","outlined":"","dense":"","type":_vm.getStatusColor(status),"color":_vm.getStatusColor(status)}},[_vm._v(" "+_vm._s(status)+" ")])],1),_c('div',{staticClass:"container-description"},[_c('h4',[_vm._v(" Descrição: ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.sanitizedDescription(complaint))}})]),_c(VDivider)],1)}),1),_c('div',{staticClass:"d-flex justify-end mt-5"},[_c(VBtn,{staticClass:"ml-5",attrs:{"color":"error"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }