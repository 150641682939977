<template>
  <div>
    <v-card>
      <v-card-title class="pt-0 pb-0">
        <span class="mr-4"> Listagem </span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-6"
          label="Buscar Nome, Filial"
          outlined
          dense
          @keyup.enter="findFilterResult"
        />

        <v-btn
          color="warning"
          class="mr-4 ml-4"
          outlined
          @click="findFilterResult()"
        >
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiFilterPlusOutline }}
          </v-icon>
          Filtrar
        </v-btn>

        <v-btn
          class="mr-4"
          outlined
          data-test="button-clear-filter"
          @click="updatedTable()"
        >
          <v-icon
            size="25"
            class="mr-2"
          >
            {{ icons.mdiFilterRemoveOutline }}
          </v-icon>
          Limpar Filtros
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoading"
        sort-by="name"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.options="{ item }">
          <v-icon
            medium
            color="warning"
            class="me-2"
            @click="openModal('details', item)"
          >
            {{ icons.mdiEye }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showDetails"
      width="700px"
    >
      <Details
        :key="employeeId"
        :employee-id="employeeId"
        @updatedTable="updatedTable()"
        @close="showDetails = false"
      ></Details>
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiBookmarkMultiple, mdiClipboardText, mdiEye, mdiFilterPlusOutline, mdiFilterRemoveOutline,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import Details from './Details.vue'

export default {
  components: {
    Details,
  },

  mixins: [formatters, messages],

  data() {
    return {
      lastStatus: '',
      employeeId: '',
      filterData: '',

      userProfile: {},
      userPermissions: [],

      headers: [
        {
          text: 'NOME',
          value: 'name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'FILIAL',
          value: 'fantasy_name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'NÚMERO DE RICS',
          value: 'amount_ric',
          sortable: true,
          align: 'center',
        },
        {
          text: 'NÚMERO DE SACS',
          value: 'amount_sac',
          sortable: true,
          align: 'center',
        },
        {
          text: 'OPÇÕES',
          value: 'options',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      isLoading: false,
      showFilter: false,
      showDetails: false,

      icons: {
        mdiEye,
        mdiClipboardText,
        mdiBookmarkMultiple,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.userPermissions = localStorageSlim.get('userPermissions', { decrypt: true })
    this.hierarchicalProfile = this.userProfile?.hierarchical_profile

    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns
        .get('api/v1/sgq/sac/all')
        .then(response => {
          const { data } = response.data

          this.itemsTable = data
          this.listOfFilteredItems = data
        })
        .catch(error => this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' }))
        .finally(() => { this.isLoading = false })
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(
        // eslint-disable-next-line camelcase
        ({ name, fantasy_name }) => {
          const nameLower = name.toLowerCase()
          const fantasyNameLower = fantasy_name.toLowerCase()

          return nameLower.includes(filterDataLower) || fantasyNameLower.includes(filterDataLower)
        },
      )
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, data = {}) {
      const modal = {
        filter: () => { this.showFilter = true },
        details: () => {
          this.showDetails = true
          this.employeeId = data?.employer_id
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error('Modal type not found')
    },
  },
}
</script>
